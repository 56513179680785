<script setup>
const { $notify } = useNuxtApp()

let openDialog = ref(false)
const url = ref('')
const addRet = reactive({ pending: false, error: '' })
const ret = reactive({ error: false, pending: false, data: {} })

// TODO: could be improved
async function open (u) {
  url.value = u
  openDialog.value = true
  try {
    ret.pending = true
    ret.data = await $fetch('/api/post/getURL', { method: 'POST', body: { URL: url.value } })
  } catch (e) {
    openDialog.value = false
    $notify(e, 'error')
  }
  ret.pending = false
}
defineExpose({ open })
const emit = defineEmits(['added'])

async function addPost () {
  addRet.pending = true
  try {
    await $fetch('/api/post/addURL', { method: 'POST', body: { URL: url.value, tags: selectedTags.value } })
    openDialog.value = false
    addRet.error = null
    $notify('Post added', 'success')
    emit('added')
  } catch (e) {
    $notify('Something goes wrong: ' + e, 'warning')
    console.error(e)
  }
  addRet.pending = false
}

// tags
const selectedTags = ref()
const searchTag = ref('')
const query = computed(() => `/api/tag?query=${searchTag.value}&limit=10`,)
const { data: tags = [], pending: loadingTags } = await useLazyFetch(query)

</script>
<template>
  <div>
    <v-dialog v-model="openDialog" width="700">
      <v-card :loading="ret.pending || addRet.pending"> 
        <v-card-title>{{ $t('post.add_custom') }}
          <v-card-subtitle>{{ $t('post.add_custom_post_description') }} {{ url }}</v-card-subtitle>
        </v-card-title>
        <v-card-text v-if="!ret.pending && ret.error">
          <v-alert icon='mdi-fire' color='error' :text="ret.error.toString()" ></v-alert>
        </v-card-text>
        <v-card-text v-if="!ret.pending && !ret.error">
          <Post v-if='ret.data' :post="ret.data" preview/>

          <v-combobox class='mt-2' v-model="selectedTags" multiple
            v-model:search='searchTag'
            persistent-hint
            :hint="$t('post.add_custom_post_tag_description')"
            :items="tags" :loading="loadingTags" item-value='id' item-title='name'
            :label="$t('Tags')" chips closable-chips hide-no-data />

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="openDialog = false">{{ $t('Close')}}</v-btn>
          <v-btn color="success" :disabled="addRet.pending" v-if="!ret.error" @click="addPost" :loading="addRet.pending">{{ $t('Add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
